.calculationsSection {
    display: flex;
    justify-content: space-between;
}

.sectionSplitter {
    width: 46%;
}

.calculationRow {
    display: flex;
    flex-direction: row;
}

.calculationRow:not(:first-child) {
    margin-top: 30px;
}

.calculationColumn {
    display: inline-flex;
    flex-direction: row-reverse;
}

.calculationColumn .tooltipContainer {
    align-items: initial;
}

.calculationColumn:nth-child(1) {
    width: 300px;
}

.calculationColumn:nth-child(2) {
    width: 150px;
    margin-left: 25px;
}

.calculationColumn span {
    font-family: "Rubik";
    font-size: 14px;
    white-space: pre-wrap;
    font-weight: var(--font-weight-medium);
    margin-top: auto;
    margin-bottom: auto;
}

.calculationColumnValue span {
    margin-bottom: 4px;
    margin-left: 4px;
    margin-top: auto;
}

.totalTaxesMinMaxColumn {
    flex-direction: column;
    gap: 10px;
}