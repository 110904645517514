@import './GlobalStyles.css';

.header {
    width: var(--desktop-width);
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

.headerLogoAndLanuage {
    display: flex;
    flex-direction: row;
}

.headerLogoAndLanuage svg {
    height: 80px;
}

.headerLogoAndLanuage span {
    margin: auto 0 auto auto;
}

.headerLogoAndLanuage select {
    margin-left: 7px;
    padding: 4px;
}

.headerInfo {
    background-color: var(--dark-green);
    color: var(--white-text);
    margin-top: 30px;
    padding-bottom: 20px;
    padding-top: 20px;
    margin-bottom: 40px;
}

.headerInfoSection {
    width: var(--desktop-width);
    margin: auto;
}

.hiddenAccessibilityAlertMessage {
    display: none;
}

.sr-only {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
    margin: -1px;
  }

.headerInfoSection div {
    margin-bottom: 15px;
}

.headerInfoSection h2 {
    font-size: 22px;
}

.headerInfoSection div span {
    margin-top: 10px;
    word-break: break-word;
    font-size: 16px;
    line-height: 1.2em;
}