@import url('https://fonts.cdnfonts.com/css/rubik');

:root {
    --dark-green: #167D3F;
    --dark-grey: #454545;
    --light-grey: #EEEEEE;
    --white-text: #FFF;
    --invalid-field-color: red;
    --red-warning: #B32853;

    --font-weight-medium: 500;
    --font-weight-regular: 400;

    --field-max-width: 280px;

    --desktop-width: 960px;
}