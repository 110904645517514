@import './GlobalStyles.css';

.tooltip {
    margin-left: 10px;
}

.tooltipContainer {
    display: flex;
    align-items: flex-start;
    margin-right: 6px;
    margin-left: auto;
    padding-left: 6px;
}

.tooltipIcon {
    color: var(--dark-green);

}