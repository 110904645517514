@import './GlobalStyles.css';

.footer {
    background-color: var(--light-grey);
    padding-bottom: 100px;
    padding-top: 50px;
}

.footerSection {
    width: var(--desktop-width);
    margin: auto;
}

.footerSection span {
    font-size: 13px;
}

.footerLogos {
    margin-top: 50px;
    text-align: center;
}

.footerLogos svg {
    height: 45px;
}

.footerLogos img {
    height: 35px;
    vertical-align: top;
    margin-left: 40px;
}

.footerLogos svg:not(:first-child) {
    margin-left: 40px;
}