@import './GlobalStyles.css';

body {
  font-family: "Rubik";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-width: var(--desktop-width);
}

input:not(input[type='checkbox']),
.calendarInput  {
  width: 100%;
  max-width: 250px;
  height: 2.285em;
  padding: 0;
}

.mt-49 {
  margin-top: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.pull-right {
  margin-left: auto;
  display: flex;
}


.formValidated input:invalid {
  border: 1px solid var(--invalid-field-color);
}

.inputInvalidErrorMessage {
  color: var(--red-warning);
  position: absolute;
  margin-top: 10px;
  max-width: 250px;
}