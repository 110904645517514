@import './GlobalStyles.css';

:root {
    --max-field-header-width: 300px;
}

#calculator {
    width: var(--desktop-width);
    margin: auto;
}

.sectionContainerButton {
    margin-top: 2em;
}

.sectionContainerButton .row {
    margin-bottom: 10px;
}

.sectionHeading h1 {
    font-weight: var(--font-weight-medium);
    font-size: 32px;
    color: var(--dark-green);
}

.sectionHeading h2 {
    font-weight: var(--font-weight-regular);
    font-size: 26px;
    color: var(--dark-green);
}

.sectionHeading h3 {
    font-weight: var(--font-weight-regular);
    font-size: 22px;
    color: var(--dark-green);
}

.sectionHeading h4 {
    font-weight: var(--font-weight-regular);
    font-size: 20px;
    color: var(--dark-green);
}

.row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 2.3em;
}

.rowContainInvalidInput {
    margin-bottom: 4.3em
}

.row .rowElement {
    margin-right: 20px;
    flex-direction: column-reverse;
    display: flex;
}

.rowElement {
    width: 100%;
    max-width: var(--max-field-header-width);
}

.rowElementHeader {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 10px;
    width: 260px;
    justify-content: flex-end;
}

.rowElementHeaderInputElement {
    display: flex;
    margin-right: 10px;
}

.rowElementHeaderInputElement input {
    width: 24px;
    height: 24px;
    margin-top: auto;
    margin-bottom: auto;
}

.rowElementHeader span {
    font-family: "Rubik";
    font-size: 14px;
    white-space: pre-wrap;
    font-weight: var(--font-weight-medium);
}

.calculatorHr {
    width: 960px;
}

.baseButton {
    -moz-appearance: none;
    -webkit-appearance: none;
    -ms-flex-align: center;
    align-items: center;
    border: none;
    border-radius: 3px;
    box-shadow: none;
    display: -ms-inline-flexbox;
    display: inline-flex;
    font-size: 1rem;
    height: 2.285em;
    -ms-flex-pack: start;
    justify-content: flex-start;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: var(--dark-green);
    border: 1px solid #dbdbdb;
    color: var(--white-text);
    cursor: pointer;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0.75em;
    padding-right: 0.75em;
    text-align: center;
    white-space: nowrap;
    border-radius: 20px;
}

.baseButton[disabled] {
    opacity: 0.5;
}

.dangerButton {
    background-color: var(--red-warning);
}

.inlineHeading {
    display: flex;
    flex-direction: row;
}

.estateInfoContainer {
    border: 1px solid var(--dark-green);
    padding: 15px;
    margin-bottom: 15px;
}

.estatePercentageOwnership {
    position: relative;
}

.estatePercentageOwnership::after {
    position: absolute;
    left: var(--data-input-mask-offset);
    top: 2px;
    content: attr(data-input-mask);
    pointer-events: none;
    opacity: 0.5;
    z-index: 1;
    font-size: 13px;
}

.forestReductionEstateRow {
    display: flex;
    flex-direction: unset;
    flex-wrap: wrap;
}

.calculatedField {
    max-width: 250px;
    height: 2.285em;
    border-bottom: 1px solid var(--dark-green);
    display: flex;
    word-break: break-word;
}

.calculatedField span {
    position: relative;
}

.preventPrintingMessage {
    height: 16px;
    margin-bottom: auto;
    margin-top: auto;
    margin-right: 5px;
}